body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-image: url(Background.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
@font-face {
  font-family: "CryptoFont";
  src: local("CryptoFont"), url(./fonts/Hate\ Agent.otf) format("truetype");
}

@font-face {
  font-family: "TWeb";
  src: local("TWeb"), url(./fonts/TitilliumWeb-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./fonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url(./fonts/Oswald-VariableFont_wght.ttf) format("truetype");
}

html {
  scroll-behavior: smooth;
}
