body {
  margin: 0;
  min-height: 100vh;
}

.App {
  background-image: url(Background.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
@font-face {
  font-family: "CryptoFont";
  src: local("CryptoFont"), url(./fonts/Hate\ Agent.otf) format("truetype");
}

@font-face {
  font-family: "TWeb";
  src: local("TWeb"), url(./fonts/TitilliumWeb-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./fonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url(./fonts/Oswald-VariableFont_wght.ttf) format("truetype");
}

html {
  scroll-behavior: smooth;
}
